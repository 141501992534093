.hud {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: inherit;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	pointer-events: none;
}

.hud > div {
	padding: 16px;
	pointer-events: all;
}

.hud__top {
	align-self: flex-start;
}

.hud__middle {
	align-self: center;
}

.hud__bottom {
	align-self: flex-end;
}

.hud__left {
	justify-self: flex-start;
}

.hud__center {
	justify-self: center;
}

.hud__right {
	justify-self: flex-end;
}

.hud__top.hud__left > div {
	display: flex;
}

.hud__top.hud__left > div:not(:first-child) {
	margin-top: 16px;
}

.map__search {
	width: 400px;
}

.hud__map-types {
	width: 120px;
}

.hud__map-types-textfield {
	margin: 0;
	padding: 0;
}
