.zoom-settings {
	width: 40px;
}

.zoom-settings.MuiPaper-root {
	background-color: #8a00c1;
}

.zoom-settings__divider {
	background-color: rgb(255 255 255 / 12%);
}

.zoom-settings__list-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0;
	color: white;
	height: 40px;
}
